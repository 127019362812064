import { useEffect } from 'react';
import { DEFAULT_LOCALE, TRIP_LINK_PAGE_APROB } from '@constants/index';
import { useOutsideTranslation } from '@utils/hooks/useOutsideTranslation';
import { GetStaticProps, InferGetStaticPropsType } from 'next';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

const NotFoundError = dynamic(() => import('@components/errors/NotFoundError'), { ssr: false });

export const getStaticProps = (async ({ locale, defaultLocale }) => {
  return {
    props: {
      locale: locale || defaultLocale || DEFAULT_LOCALE
    },
    revalidate: 3600
  };
}) satisfies GetStaticProps;

const PageNotFound = (props: InferGetStaticPropsType<typeof getStaticProps>) => {
  useOutsideTranslation(props.locale);
  const router = useRouter();

  const path = router.asPath;
  const isReady = router.isReady;

  useOutsideTranslation(router.locale || DEFAULT_LOCALE);

  useEffect(() => {
    if (!isReady) {
      return;
    }

    const isProfileTripApro = TRIP_LINK_PAGE_APROB.some((link) => path.includes(link));
    if (isProfileTripApro) {
      router.replace('/profile/trips?tab=confirmed');
    }
  }, [isReady, path, router]);

  if (isReady && TRIP_LINK_PAGE_APROB.some((link) => path.includes(link))) {
    return null;
  }

  return <NotFoundError />;
};

export default PageNotFound;
